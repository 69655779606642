/* Webfont: Lato-Regular */
@font-face {
  font-family: 'LatoWeb';
  src: url('../../fonts/Lato-Regular.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/Lato-Regular.woff2') format('woff2'), /* Modern Browsers */
       url('../../fonts/Lato-Regular.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/Lato-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/*@font-face {
  font-family: 'ZCOOL QingKe HuangYou', cursive;
  src: url('../fonts/ZCOOLQingKeHuangYou-Regular.ttf') format('truetype');
}*/

@font-face {
  font-family: 'Alibaba-PuHuiTi-Regular', regular;
  src: url('../../fonts/Alibaba-PuHuiTi-Regular.ttf') format('truetype');
}

$white: #fff;
$lightGrey: #f3f3f3;
$grey: #e6e6e6;
$blue: #2e8ef9;
$green: #00bc5c;
$boxShadow: rgba(0, 0, 0, 0.04);

body {
  margin: 0px;
  background-color: #f9fbfc;
}

 input:focus, textarea {
  outline: none !important;
}

.swal2-popup .swal2-title {
  text-transform: capitalize;
}

.globalPayin {

  .message {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 20px;
    max-width: 500px;
    margin: 0 auto;

    h3 {
      width: 100%;
      border-top: 2px solid $grey;
      padding: 30px 40px 0;
    }

    span {
      width: 100%;
      border-bottom: 2px solid $grey;
      padding: 0 40px 30px;
      line-height: 26px;
    }
  }

  /*HEADER*/
  .header {
    display: flex;
    position: fixed;
    width: 100%;
    height: 80px;
    z-index: 7;
    box-shadow: 0px 0px 15px 1px $boxShadow;
    border-bottom: 1px solid $grey;
    background-color: #fff;

    .title {
      font-weight: 400;
      padding-left: calc(20% + 25px);
    }
  }

  .loadingScreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    background: rgb(34, 54, 121);
    background: #f9fbfc;
  }

  .loader-text {
    font-size: 21px;
    margin-top: 20px;
  }

  /*INNER CONTAINER*/
  .inner-container {
    width: 80%;
    height: calc(100vh - 80px);
    padding: 80px 0 0 calc(20%);

    .main {
      display: flex;
      width: 100%;
      background-color: transparent;

      .content-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 30px 50px 30px 40px;

          .sidebar {
            position: fixed;
            left: 0;
            top: 0;
            width: calc(20% - 60px);
            height: 100vh;
            padding: 0 30px;
            border-right: 1px solid $grey;
            background-color: $white;
            box-shadow: 0px 0px 15px 1px $boxShadow;
            color: #2c2c2c;
            z-index: 8;

            .title {
              margin-bottom: 30px;
            }

            .item {
              margin-bottom: 20px;
              font-size: 18px;

              span {
                color: #a8a7a7;
              }
            }
          }

        .steps {
          display: flex;
          width: 100%;
          border-style: solid;
          border-width: 1px;
          border-color: $grey;
          border-radius: 8px;
          background-color: $white;
          box-shadow: 0px 0px 15px 1px $boxShadow;
          margin-bottom: 30px;

          .step {
            display: flex;
            width: calc(100% / 3);
            justify-content: center;
            flex-direction: column;
            align-items: center;
            padding: 15px;
            color: #cdcdcd;
            font-size: 14px;
            text-transform: uppercase;

            &.active {
              background-color: $blue;
              color: #fff;

            }

            img {
              margin-bottom: 2px;
            }

            .number {
              font-size: 28px;
              line-height: 24px;
            }
          }
        }

        .form {
          display: flex;
          flex-direction: column;
          width: calc(100% - 80px);
          border-style: solid;
          border-width: 1px;
          border-color: $grey;
          border-radius: 8px;
          background-color: $white;
          box-shadow: 0px 0px 15px 1px $boxShadow;
          padding: 40px;

          .inputs {
            display: flex;
            flex-direction: column;
            margin-bottom: 25px;


            .input-column {
              display: flex;
              position: relative;
              margin-bottom: 25px;
              border-bottom: 1px solid $grey;
              padding-bottom: 20px;

              label {
                width: 40%;
                display: flex;
                flex-direction: column;
                font-size: 18px;

                span {
                  color: #a8a7a7;
                  font-size: 12px;
                  max-width: 60%;
                  margin-top: 5px;
                }
              }

              /* Chrome, Safari, Edge, Opera */
              input::-webkit-outer-spin-button,
              input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }

              /* Firefox */
              input[type=number] {
                -moz-appearance: textfield;
              }

              .form-control {
                width: 60%;
                height: 15px;
                border: 1px solid $grey;
                border-radius: 4px;
                background-color: $white;
                color: #2c2c2c;
                padding: 15px 10px;

                &.valid {
                  border-color: $green;
                }

                &[disabled] {
                  opacity: 0.5;
                  cursor: not-allowed;
                }
              }

              .check {
                position: absolute;
                right: 10px;
                top: 13px;
              }

              .select-container {
                width: calc(60% + 22px);

                span.label {
                  display: inline-block;
                  margin-bottom: 15px;
                }

                &.valid [class*="control"] {
                  border-color: $green;
                }
                & [class*="ValueContainer"] {
                  padding: 10px 8px;
                }
              }
            }
          }

          .actions {
            margin-bottom: 40px;

            .btn {
              border-style: solid;
              border-width: 1px;
              border-color: $grey;
              border-radius: 4px;
              background-color: rgb(235, 236, 240);
              color: #a5a5a5;
              padding: 20px 0;
              cursor: pointer;

              &.btn-cancel {
                width: calc(36% - 15px);
              }

              &.btn-submit {
                width: calc(64% - 15px);
                background-color: $blue;
                color: $white;
                margin-left: 30px;

                &[disabled] {
                  opacity: 0.4;
                  cursor: default;
                  pointer-events: none;
                }
              }
            }
          }

          .safety-icons {
            display: flex;
            justify-content: space-around;
          }
        }
      }
    }
  }

  /*FOOTER*/
  .footer {
    display: none !important;
  }

  .test-provider-page {
    padding: 15px;
    background-color: $lightGrey;
    min-height: 100vh;

    .container {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 1000px;
      margin: 0 auto;

      .row {
        padding-left: 15px;
        padding-right: 15px;

        .title {
          text-align: center;
          margin: 5px 0 20px;
        }

        &.main-row {
          display: flex;
          flex-direction: column;
          align-items: center;
          border: 1px solid $grey;
          border-radius: 10px;
          background-color: $white;
        }

        &.order-details {
          padding-top: 0;
          padding-bottom: 20px;

          .info {
            display: flex;
            flex-direction: column;

            td.desktop-only {
              width: 100px;
            }

            .item {
              padding: 5px;

              span {
                display: inline-block;
                min-width: 80px;
                color: #828282;
              }
            }
          }
        }

        &.controls {
          margin-top: 20px;
          padding: 0 25px;

          .status-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding-top: 10px;
            padding-bottom: 15px;
            border-bottom: 1px solid $grey;

            &:last-child {
              border-bottom: 0;
            }

            .text {
              padding-right: 25px;
            }

            h3 {
              margin-bottom: 10px;
            }

            .description {
              color: #cdcdcd;
              font-weight: 300;
              font-size: 14px;
              line-height: 21px;
            }

            .actions {
              display: flex;
              flex-direction: column;

              input {
                padding: 15px;
                margin-bottom: 10px;
                border-width: 1px;
                border-radius: 4px;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;

                &.valid {
                  border-color: $green;
                }
              }

              .btn-submit {
                border-style: solid;
                border-width: 1px;
                border-color: $grey;
                border-radius: 4px;
                padding: 25px 0;
                cursor: pointer;
                background-color: $blue;
                color: $white;
                width: 250px;
                font-size: 14px;
                letter-spacing: 1px;

                &:disabled {
                  opacity: 0.5;
                  cursor: initial;
                }

                &.btn-submit-green {
                  background-color: $green;
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    .header {
      position: relative;
      height: unset;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding-top: 10px;

      .title {
        padding-left: 0;
        margin: 10px 0;
      }
    }
    .inner-container {
      width: 100%;
      padding-left: 0;
      padding-top: 0;

      .main {

        .content-wrapper {
          padding: 20px;

          .sidebar {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            width: 100%;
            height: unset;
            top: unset;
            left: unset;
            padding: 0;
            border-style: solid;
            border-width: 1px;
            border-color: $grey;
            border-radius: 8px;
            background-color: $white;
            box-shadow: 0px 0px 15px 1px $boxShadow;
            margin-bottom: 20px;

            .row {
              display: flex;
              justify-content: space-around;
              width: 50%;
            }

            .item.ticket-id {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-bottom: 5px;
            }
          }

          .steps {
            margin-bottom: 20px;
          }

          .form {
            width: calc(100% - 40px);
            padding: 20px;

            .inputs {
              margin-bottom: 5px;

              .input-column {
                align-items: center;

                label {
                  font-size: 12px;

                  span {
                    display: none;
                  }
                }
              }
            }

            .actions {
              .btn.btn-cancel {
                width: calc(36% - 7px);
              }

              .btn.btn-submit {
                margin-left: 15px;
                width: calc(64% - 8px);
              }
            }
          }
        }
      }
    } 
  }

  @media screen and (max-width: 767px) {
    .header {
      .title {
        font-size: 24px;
      }
    }

    .inner-container {
      .main {
        .content-wrapper {
          .steps {
            .step {
              padding: 10px;
              flex-direction: row;

              img {
                margin-right: 5px;
              }

              .number {
                display: none;
              }
            }
          }

          .sidebar {
            width: calc(100% - 20px);
            padding: 10px;
            text-align: center;

            .title {
              display: none;
            }

            .row {
              width: 100%;
            }
          }
        }
      }
    }

    .test-provider-page .container .row.order-details .info {
      .item {
        display: block;
      }

      .desktop-only {
        display: none;
      }
    } 
  }

  @media screen and (max-width: 599px) {
    .test-provider-page {
      padding: 15px;

      .container {
        .row {
          &.controls {
            padding: 15px;
            margin-top: 25px;

            .status-row {
              padding-top: 25px;
              padding-bottom: 25px;
              flex-direction: column;
              align-items: flex-start;

              h3 {
                margin-top: 0;
              }

              .actions {
                width: 100%;
                margin-top: 20px;

                .btn-submit {
                  width: 100%;
                  padding: 15px 0;
                }
              }
            }
          }

          .title {
            margin-bottom: 25px;
          }
        }
      }
    }
  }
}
